<template>
    <div>
        <!--信息展示组件-->
        <business-apply-show v-if="showBusinessApplyShow" :id="currentData.businessFormId" :showBack="false" @clickStep="clickStep"></business-apply-show>
        <div class="submit-btn-group">
            <el-button @click="closeForm">关闭</el-button>
            <el-button type="primary" @click="submit()">收到</el-button>
        </div>
    </div>
</template>

<script>
/**
 * 通用信息展示组件
 */
import BusinessApplyShow from '../../businessApply/businessApplyShow';
export default {
    components: {
        BusinessApplyShow,
    },
    data() {
        return {
            currentData: {},
            showBusinessApplyShow: false,
            data: {}
        };
    },
    methods: {
        init(item) {
            this.workflowTaskId = item.id;
            this.currentData = item;
            switch (item.processDefinitionKey) {
                case 'flow_business_apply':
                    this.showBusinessApplyShow = true;
                    break;
                case 'flow_drawing_apply':
                    break;
                case 'flow_overhaul':
                case 'flow_overhaul_1':
                    break;
                case 'flow_emergency_repair':
                    break;
                case 'flow_put_into_production':
                    break;
                default:
                    break;
            }
        },
        submit() {
            this.$client.completeNoticeTask(this.workflowTaskId).then(() => {
                this.$message({
                    message: '操作成功',
                    type: 'success'
                });
                this.closeForm(2);
            });
        },
        closeForm(type) {
            this.$emit('closeForm', type);
        },
        clickStep(index) {
            //this.$emit('clickStep', index);
        }
    }
};
</script>
