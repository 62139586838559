<template>
    <div>
    <div class="form-table">
        <!--信息展示组件-->
        <put-into-production-detail v-if="showPutIntoProductionDetail" ref="ref_production_detail"></put-into-production-detail>
        <put-into-production-create v-if="showPutIntoProductionCreate" ref="ref_production-create"></put-into-production-create>
        <put-into-production-receive v-if="showPutIntoProductionReceive" ref="ref_production_receive"></put-into-production-receive>
       <!--审核历史-->
        <record-history v-if="showRecordHistory" :instance="currentData"/>
        <!--end-->
    </div>
    <div class="submit-btn-group button-fixed">
        <el-button @click="closeForm">关闭</el-button>
    </div>
    </div>
</template>

<script>
/**
 * 通用信息展示组件
 */
import PutIntoProductionDetail from '@/pages/putIntoProduction/putIntoProductionDetail';
import PutIntoProductionCreate from '@/pages/putIntoProduction/putIntoProductionCreate';
import PutIntoProductionReceive from '@/pages/putIntoProduction/putIntoProductionReceive';
import RecordHistory from '@/components/workflow/RecordHistory';
export default {
    components: {
        PutIntoProductionDetail,
        PutIntoProductionCreate,
        PutIntoProductionReceive,
        RecordHistory
    },
    data() {
        return {
            currentData: {},
            showRecordHistory: false,
            showPutIntoProductionDetail: false,
            showPutIntoProductionCreate: false,
            showPutIntoProductionReceive: false,
            data: {}
        };
    },
    created() {
        this.init(JSON.parse(sessionStorage.getItem(this.$route.query.id)));
    },
    methods: {
        init(item) {
            this.currentData = item;
            this.showRecordHistory = true;
            this.showPutIntoProductionDetail = true;
            this.$nextTick(() => {
                this.$refs.ref_production_detail.init(item);
            });
            // switch (item.curNode.code) {
            //     case 'elec_overhaul_planned_receipt':
            //         this.showOverhaulReceive = true;
            //         this.$nextTick(() => {
            //             this.$refs.ref_overhaul_receive.init(item);
            //         });
            //         break;
            //     case 'elec_overhaul_planned_first_review':
            //     case 'elec_overhaul_planned_second_review':
            //     case 'elec_overhaul_planned_third_review':
            //     case 'elec_overhaul_planned_fourth_review':
            //     case 'elec_overhaul_planned_fifth_review':
            //         this.showFlowOverhaulDetail = true;
            //         this.$nextTick(() => {
            //             this.$refs.ref_flowOverhaul_detail.init(item);
            //         });
            //         break;
            //     case 'elec_overhaul_planned_apply':
            //         this.showOverhaulApply = true;
            //         this.$nextTick(() => {
            //             this.$refs.ref_flowOverhaul_detail.init(item);
            //         });
            //         break;
            //     default:
            //         break;
            // }
        },
        closeForm(type) {
            //this.$emit('closeForm', type);
            this.$root.$emit('fe-framework:tab:close', this.$route.fullPath);
        },
        /*clickStep(index) {
            //this.$emit('clickStep', index);

            let status = this.currentData.businessFormData.phaseStatus;
            switch (index) {
                case 0:
                    this.showBusinessApplyShow = true;
                    this.showReconnaissanceShow = false;
                    this.showElectricPlanShow = false;
                    this.showCompletionApplyShow = false;
                    this.showCompletionAcceptanceShow = false;
                    break;
                case 1:
                    if (status >= '10') {
                        this.showReconnaissanceShow = true;
                        this.showBusinessApplyShow = false;
                        this.showElectricPlanShow = false;
                        this.showCompletionApplyShow = false;
                        this.showCompletionAcceptanceShow = false;
                    }
                    break;
                case 2:
                    if (status >= '20') {
                        this.showElectricPlanShow = true;
                        this.showBusinessApplyShow = false;
                        this.showReconnaissanceShow = false;
                        this.showCompletionApplyShow = false;
                        this.showCompletionAcceptanceShow = false;
                    }
                    break;
                case 3:
                    if (status >= '30') {
                        this.showCompletionAcceptanceShow = true;
                        this.showBusinessApplyShow = false;
                        this.showReconnaissanceShow = false;
                        this.showElectricPlanShow = false;
                        this.showCompletionApplyShow = false;
                    }
                    break;
                default:
                    break;
            }
        }*/
    }
};
</script>
<style lang="less" scoped="">
    .form-table {
        height: calc(100vh - 170px);
        margin-bottom: 10px;
        overflow-y: scroll;
    }
    .button-fixed {
        margin:  0 !important;
    }
    .submit-btn-group {
        text-align: center;
    }
</style>
